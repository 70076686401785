.no-padding-popover-inner-content .ant-popover-inner-content {
  padding: 0;
}

.no-padding-popover-title .ant-popover-title {
  padding: 0;
}

/* The following 3 css selectors are for hiding the arrows that are displayed for number fields */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
